import React from 'react'
import _ from 'lodash'
import {
  useToast
} from '@chakra-ui/react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import image01 from '../../landingsAssets/RealEstateImages/image01.png'
import image02 from '../../landingsAssets/RealEstateImages/image02.png'
import image03 from '../../landingsAssets/RealEstateImages/image03.jpg'
import image05 from '../../landingsAssets/RealEstateImages/image05.jpg'
import image06 from '../../landingsAssets/RealEstateImages/image06.jpg'
import image07 from '../../landingsAssets/RealEstateImages/image07.png'
import image09 from '../../landingsAssets/RealEstateImages/image09.png'
import image12 from '../../landingsAssets/RealEstateImages/image12.jpg'
import image13 from '../../landingsAssets/RealEstateImages/image13.jpg'
import icons from '../../landingsAssets/icons.svg'
import LandingHeader from '../../components/header/LandingHeader'
import LinkWithGtag from '../../components/landings/LinkWithGtag'

const BACKEND_URL = 'https://us-central1-tenantflow-ace23.cloudfunctions.net/p/sendBookDemoNotification'

const RealEstate = () => {
  const toast = useToast()

  const openToast = () => (
    toast({
      title: 'Thank you!',
      description: 'We will contact you ASAP to schedule our quick FaceFile Demo.',
      status: 'success',
      duration: 9000,
      isClosable: true
    })
  )
  const onSubmit = async (e) => {
    e.preventDefault()
    const form = e.target
    const name = form[0].value
    const companyName = form[1].value
    const email = form[2].value
    const phoneNumber = form[3].value
    await fetch(BACKEND_URL, {
      method: 'POST',
      body: JSON.stringify(_.omitBy({
        name,
        companyName,
        email,
        phoneNumber
      }, _.isEmpty))
    })
    openToast()
    form.reset()
  }
  return (
    <div id='wrapperLanding'>
      <LandingHeader
        title='FaceFile: One way video interviews to screen candidates faster'
        stylesHref='/styles/realEstate.css'
      />
      <div id='main'>
        <div className='inner' style={{ paddingTop: 0 }}>
          <section id='home-section'>
            <div id='container02' className='container columns full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <div id='image02' className='image' data-position='center'>
                      <LinkWithGtag href='https://facefile.co/' className='frame'>
                        <img src={image02} alt='Homepage' />
                      </LinkWithGtag>
                    </div>
                    <h3 id='text04'>Enhance property marketing and tenant communications with video</h3>
                    <p id='text10'>Use AI-powered video forms to collect video responses from your existing and potential tenants.</p>
                    <div id='list02' className='list'><ul><li><p>Obtain <strong>in-depth video feedback</strong> to improve your tenant experience</p></li><li><p>Empower your property marketing with authentic <strong>video testimonials</strong></p></li><li><p><strong>Qualify dozens of rental leads</strong> in the time it takes to make a single call.</p></li></ul></div>
                  </div>
                  <div id='demo-form'>
                    <p id='text12'>Book a Live Demo</p>
                    <p id='text03'>We will walk you through the platform and show how you can enhance tenant management and property marketing with video.</p>
                    <form id='form01' data-autofocus='1' onSubmit={onSubmit}>
                      <div className='inner'>
                        <div className='field'>
                          <input type='text' name='full-name' id='form01-full-name' placeholder='Full name*' maxLength='256' required />
                        </div>
                        <div className='field'>
                          <input type='text' name='company-name' id='form01-company-name' placeholder='Company name*' maxLength='256' required />
                        </div>
                        <div className='field'>
                          <input type='email' name='work-email' id='form01-work-email' placeholder='Work email*' maxLength='128' required />
                        </div>
                        <div className='field'>
                          <input type='number' name='phone-number' id='form01-phone-number' placeholder='Phone number (optional)' maxLength='128' data-category='integer' step='1' />
                        </div>
                        <div className='actions'>
                          <button type='submit'>Request a demo</button>
                        </div>
                      </div>
                      <input type='hidden' name='id' value='form01' />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div id='container05' data-scroll-id='scrollpoint01' data-scroll-behavior='default' data-scroll-offset='0' className='container default full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <h3 id='text02'>AI-powered interactive video forms</h3>
                  <p id='text14'><span className='p'>Get an “in-person feel” for your tenants with beautiful video forms without having to record any videos or come up with the right questions to ask.<br />Choose from one of our proven templates with pre-recorded video questions, or enter your own question, and a custom video will be generated in seconds.</span></p>
                  <div id='image03' className='image' data-position='center'>
                    <LinkWithGtag href='https://facefile.co/s/FAEt7Eub89hJI1Pfgm8g?preview' className='frame' target='_blank'>
                      <img src={image03} alt='' />
                    </LinkWithGtag>
                  </div>
                  <ul id='links05' className='links'>
                    <li className='n01'>
                      <LinkWithGtag href='https://facefile.co/s/FAEt7Eub89hJI1Pfgm8g?preview' target='_blank'>
                        View sample video form
                      </LinkWithGtag>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id='container08' data-scroll-id='scrollpoint02' data-scroll-behavior='default' data-scroll-offset='0' className='container columns full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <h3 id='text15'>Leverage the power of your tenant&#039;s voice</h3>
                    <p id='text22'>Change the way you communicate with tenants. Video forms allow you to collect valuable video responses from your tenants at every stage of the tenant lifecycle:</p>
                    <div id='list03' className='list'><ul><li><p><strong>Potential tenants</strong>: Qualify tenant leads 3X faster with automated one-way video interviews</p></li><li><p><strong>Existing tenants</strong>: Get in-depth video feedback to improve your tenant experience</p></li><li><p><strong>Loyal tenants</strong>: Collect and leverage video testimonials in real estate marketing activities</p></li><li><p><strong>Moving-out tenants</strong>: Welcome criticism from tenants to grow and improve.</p></li></ul></div>
                  </div>
                  <div>
                    <div id='image09' className='image' data-position='center'>
                      <span className='frame deferred'>
                        <img src={image09} alt='' />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id='container13' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' className='container columns full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <div id='image07' className='image' data-position='center'>
                      <span className='frame deferred'>
                        <img src={image07} alt='' />
                      </span>
                    </div>
                  </div>
                  <div>
                    <h3 id='text05'>Automate tenant communication and testimonials</h3>
                    <p id='text13'>Replace repetitive phone calls and endless emails. Simply create a video form and send it to all recipients simultaneously.</p>
                    <div id='list04' className='list'><ul><li><p>Create video forms <strong>in a few clicks</strong> from ready-made templates</p></li><li><p>Use <strong>effective interview questions</strong>, all ready to use</p></li><li><p>Create video forms without recording videos with the AI-powered <strong>text-to-video generator</strong></p></li><li><p>All responses are <strong>transcribed into text</strong> automatically</p></li><li><p><strong>Easily connect</strong> FaceFile to your PMS, leasing software and tenant portal.</p></li></ul></div>
                  </div>
                </div>
              </div>
            </div>
            <div id='container06' data-scroll-id='scrollpoint04' data-scroll-behavior='default' data-scroll-offset='0' className='container default full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <h2 id='text19'>Video Form Templates</h2>
                  <p id='text21'>Use our tried and tested templates, each tailored for a different use case.</p>
                </div>
              </div>
            </div>
            <div id='container15' className='container columns full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <div id='image05' className='image' data-position='center'>
                      <LinkWithGtag href='https://facefile.co/template/TenantPrescreening' className='frame'>
                        <img src={image05} alt='Rental Leads Qualification' />
                      </LinkWithGtag>
                    </div>
                    <ul id='links07' className='links'>
                      <li className='n01'>
                        <LinkWithGtag href='https://facefile.co/template/TenantPrescreening'>
                          Tenant Pre-Screening Interview
                        </LinkWithGtag>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div id='image06' className='image' data-position='center'>
                      <LinkWithGtag href='https://facefile.co/template/TenantSurvey' className='frame'>
                        <img src={image06} alt='Tenant Satisfaction Survey' />
                      </LinkWithGtag>
                    </div>
                    <ul id='links08' className='links'>
                      <li className='n01'>
                        <LinkWithGtag href='https://facefile.co/template/TenantSurvey'>
                          Tenant Satisfaction Survey
                        </LinkWithGtag>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div id='image12' className='image' data-position='center'>
                      <LinkWithGtag href='https://facefile.co/template/TenantExit' className='frame'>
                        <img src={image12} alt='Tenant Exit Survey' />
                      </LinkWithGtag>
                    </div>
                    <ul id='links09' className='links'>
                      <li className='n01'>
                        <LinkWithGtag href='https://facefile.co/template/TenantTestimonials'>
                          Tenant Testimonials Form
                        </LinkWithGtag>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div id='image13' className='image' data-position='center'>
                      <LinkWithGtag href='https://facefile.co/template/TenantExit' className='frame'>
                        <img src={image13} alt='Tenant Exit Survey' />
                      </LinkWithGtag>
                    </div>
                    <ul id='links10' className='links'>
                      <li className='n01'>
                        <LinkWithGtag href='https://facefile.co/template/TenantExit'>
                          Tenant Exit Survey
                        </LinkWithGtag>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id='container09' className='container default full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <p id='text35'><span className='p'>Didn&#039;t find a ready-to-use template? Create your own video form in minutes.<br />Choose from dozens of pre-recorded video questions or customize your own questions with the AI-powered text-to-video generator.</span></p>
                  <ul id='buttons06' className='buttons'>
                    <li>
                      <AnchorLink to='/usecase/RealEstate#demo-form' className='button n01'>
                        Request a demo
                      </AnchorLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id='container10' data-scroll-id='scrollpoint05' data-scroll-behavior='default' data-scroll-offset='0' className='container default full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <h1 id='text32'>How FaceFile works</h1>
                  <p id='text11'>FaceFile is a simple yet powerful video survey and video messaging platform. Creating and publishing an interactive video form only takes a few minutes.</p>
                </div>
              </div>
            </div>
            <div id='container07' className='container columns full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <ul id='icons02' className='icons'>
                      <li>
                        <LinkWithGtag className='n01' href='https://domain.ext/path' aria-label='Check'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                          >
                            <use xlinkHref={`${icons}#check-light`} />
                          </svg>
                          <span className='label'>Check</span>
                        </LinkWithGtag>
                      </li>
                    </ul>
                    <p id='text30'>Select a video form template</p>
                    <p id='text26'>Select a pre-made form template with pre-recorded video questions. Or customize with questions of your own.</p>
                  </div>
                  <div>
                    <ul id='icons03' className='icons'>
                      <li>
                        <LinkWithGtag className='n01' href='https://domain.ext/path' aria-label='Link'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                          >
                            <use xlinkHref={`${icons}#link`} />
                          </svg>
                          <span className='label'>Link</span>
                        </LinkWithGtag>
                      </li>
                    </ul>
                    <p id='text31'>Send a link to tenants</p>
                    <p id='text27'>Send the link to your tenants or tenant leads. Tenants can self-record video testimonials and responses at their own pace.</p>
                  </div>
                  <div>
                    <ul id='icons01' className='icons'>
                      <li>
                        <LinkWithGtag className='n01' href='https://domain.exhttps://images.ctfassets.net/1d5sc6vl6pww/6OlrMc6hSdcEpQJguTXycl/3900ba2d48eaf8e393865843eb47bab4/icon.pngt/path' aria-label='Star'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                          >
                            <use xlinkHref={`${icons}#star`} />
                          </svg>
                          <span className='label'>Star</span>
                        </LinkWithGtag>
                      </li>
                    </ul>
                    <p id='text17'>View and share tenant responses</p>
                    <p id='text23'>Sit back, watch video responses, and read response transcripts. Share video testimonials on your website or on social media.</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              id='container04'
              data-scroll-id='scrollpoint06'
              data-scroll-behavior='default'
              data-scroll-offset='0'
              className='container columns'
            >
              <div className='wrapperLanding'>
                <div className='inner'>
                  <div>
                    <div id='image01' className='image' data-position='center'>
                      <span className='frame'>
                        <img src={image01} alt='' />
                      </span>
                    </div>
                  </div>
                  <div>
                    <p id='text20'><strong>Zach Gunner</strong>, PROPERTY MANAGER</p>
                    <p id='text16'>Uses FaceFile for qualifying tenant leads coming from real estate listings</p>
                    <p id='text07'><em>“The click and send simplicity of FaceFile allows us to narrow down the pool of choices much faster and free up time by not having to spend hours with in-person meetings.”</em></p>
                  </div>
                </div>
              </div>
            </div>
            <div id='container11' data-scroll-id='scrollpoint07' data-scroll-behavior='default' data-scroll-offset='0' className='container default full screen'>
              <div className='wrapperLanding'>
                <div className='inner'>
                  <h3 id='text01'>Ready to take your tenant communication to the next level?</h3>
                  <ul id='buttons01' className='buttons'>
                    <li>
                      <AnchorLink to='/usecase/RealEstate#demo-form' className='button n01'>
                        Request a demo
                      </AnchorLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default RealEstate
